<div class="clase">
  <p-dialog header="Eliminar inmueble"
            [closable]="false"
            *ngIf="displayModal && !procesando && !exito"
            [(visible)]="displayModal && !procesando && !exito"
            [modal]="true" [style]="{width: '45vw'}"
            [baseZIndex]="10000"
            [draggable]="false"
            [resizable]="false">
    <div *ngIf="error">
      <p class="p-m-0 --text-error">
        {{error}}
      </p>
    </div>
    <p>¿Desea continuar?</p>
    <div class="at-control at-fieldset--span-4">
      <p-dropdown
        name="motivo"
        required
        [options]="motivo"
        [(ngModel)]="motivoId"
        optionLabel="descripcion"
        optionValue="id"
        placeholder="Seleccione motivo">
      </p-dropdown>
    </div>
    <ng-template pTemplate="footer">
      <p-button icon="pi pi-times"
                (click)="finalizar()"
                label="Cancelar"
                styleClass="btn p-button-secondary"></p-button>
      <p-button icon="pi pi-check"
                (click)="guardar()"
                label="Aceptar"
                styleClass="btn">
      </p-button>
    </ng-template>
  </p-dialog>
</div>

<p-dialog header="Eliminar inmueble"
          [closable]="false"
          *ngIf="displayModal && exito"
          [(visible)]="displayModal && exito"
          [modal]="true" [style]="{width: '30vw'}"
          [baseZIndex]="10000"
          [draggable]="false"
          [resizable]="false">
  <div *ngIf="error">
    <p class="p-m-0 --text-error">
      {{error}}
    </p>
  </div>
  <p>Se eliminó inmueble exitosamente</p>
  <ng-template pTemplate="footer">

    <p-button (click)="finalizar()"
              label="Ver"
              styleClass="btn">
    </p-button>
  </ng-template>
</p-dialog>
