import { IsBoolean, IsEmail, IsOptional, IsString } from 'class-validator'
export class DtoContactoGeneral {
  @IsString()
  nombre: string
  @IsString()
  telefono: string
  @IsEmail()
  correo: string
  @IsBoolean()
  patrocinio: boolean
  @IsString()
  observaciones: string

  @IsOptional()
  @IsString()
  text?: string

  @IsOptional()
  @IsString()
  subject?: string
}

export interface IInmuebleBusqueda {
  ciudad: string
  tipoInmuebleId: number[] | null
  zonas: number[]
  precios: number[]
  categoriaId: number | null
  order: string | null
}
export type IInmuebleCaracteristicasOpciones = {
  id?: number
  descripcion: string
  codigo: number
  required: null
  icon: string
  opciones: number[]
  ngModel: null
}
export type ICaracteristicas = {
  [param: string]: string
}
export interface IInmueble {
  id?: any
  codigo: number | string | any
  contenido: string
  empresa: number
  dominio: number
  titulo: string
  descripcion: string
  precio: number | string | any
  motivo: {
    id: any
    descripcion: string
  }
  ciudad: {
    id: any
    descripcion?: string
    identificacion?: string
  }
  colonia: {
    id: any
    descripcion?: string
  }
  categoria: {
    id: any
    descripcion?: string
  }
  moneda: {
    id: any
    descripcion?: string
    abreviacion: string
  }
  calle: string
  codigoPostal: number
  numero: number
  tipo: {
    id: any
    descripcion?: string
  }
  caracteristicas: IInmuebleCaracteristica | IInmuebleCaracteristicaCatalogos[]
  caracteristicasSecundarias:
    | IInmuebleCaracteristicasSecundarias
    | ICatalogoInmuebleCaracteristicasSecundarias
    | number[]
  multimedia: IInmuebleMultimedia
  similares?: IInmueble[]
  url?: string
  imagen?: any
}
export type ITipoInmueble = {
  id?: number
  descripcion: string
  url: string
}
export type ITipoInmuebles = ITipoInmueble[]
export type ICiudad = {
  id?: number
  descripcion: string
  identificacion: string
}
export type ICiudades = ICiudad[]
export type IInmuebleMultimediaObject = {
  id?: any
  inmuebleId?: number
  fotografia: boolean
  url: string
  nombre: string
}
export type IInmuebleMultimedia = IInmuebleMultimediaObject[]
export type IInmuebleCaracteristicaCatalogos = {
  id?: any
  caracteristica: string
  cantidad: string
  icon: string
}
export type IInmuebleCaracteristica = {
  inmuebleId: number | null
  caracteristicaId: number
  cantidad: string
}[]
export type IInmuebleCaracteristicasSecundarias = {
  inmuebleId: number
  caracteristicaId: number
}[]
export type ICatalogoInmuebleCaracteristicasSecundarias = {
  id?: number
  descripcion: string
}[]
export type IInmuebles = IInmueble[]
export type INuevaCiudad = {
  id: any
  descripcion: string
  identificacion: string
}
export type INuevaCiudades = INuevaCiudad[]
export type IZona = {
  id?: any
  descripcion: string
  ciudadId?: number
}
export type IContacto = {
  nombre: string
  correo: string
  telefono: string
  mensaje: string | Date
  text?: string
  subject?: string
  tipo?: number
  url?: string
  inmueble?: string
}
export type INavegador = {
  token: string
  ultimaVez: Date
  usuarioId: number
  ip: string
  eliminado: number
  control: any[]
}
export type INavegadorControl = {
  id: number
  navegadorId: number
  canalId: number
  concedido: number
  rechazado: number
  valor: string
  rechazadoDiario: number
  accionId: number
  count: number
}
export type INavegadorControles = INavegadorControl[]
export type IPublicidad = {
  id?: number
  titulo: string
  descripcion: string
  url?: string
  enlace: string
  ciudadId: string
  foto?: File
  inmuebleId: number
  eliminado: number
}
export interface IDesarrollo {
  id: number | null
  titulo: string | null
  descripcion: string | null
  unidadesDisponibles: number | null
  codigo?: string
  colonia: {
    id: number | null
    descripcion: string | null
  }
  multimedia: {
    url: string | null
  }
  url?: string
}
export type IDesarrollos = IDesarrollo[]
