import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IInmueble, IInmuebleMultimediaObject } from '../../interface';

@Injectable({
  providedIn: 'root'
})
export class InmuebleService {
  constructor(@Inject(HttpClient) private http: HttpClient) {
  }
  agregarImagen(inmuebleId: number, imagen: any): Observable<IInmuebleMultimediaObject> {
    const formdata = new FormData();
    formdata.set('imagen', imagen);
    return this.http.post<any>(`/api/inmueble/${inmuebleId}/upload`,formdata);
  }

  get(codigo: number): Observable<IInmueble> {
    return this.http.get<IInmueble>(`/api/inmueble/${codigo}`);
  }

  delete(inmuebleId: number, data: any): Observable<any> {
    return this.http.put(`/api/inmueble/${inmuebleId}/eliminar`,{
      motivoId: data,
    });
  }
  putMotivo(inmuebleId: number, data: any): Observable<any> {

    return this.http.put(`/api/inmueble/${inmuebleId}`,{
      motivoId: data
    });
  }

  put(data: IInmueble): Observable<IInmueble> {
    return this.http.put<IInmueble>(`/api/inmueble/${data.id}`, data);
  }
  getAdmin(codigo: number): Observable<IInmueble> {
    return this.http.get<IInmueble>(`/api/inmueble/admin/${codigo}`);
  }
}
