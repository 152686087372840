<div class="card-grid tres">
<inmueble-card-inmueble
  *ngFor="let item of resultados; let i =index"
  [item]="item"
  [info]="false"
  [etiquetas]="true"
  [infoDentro]="true"
  [caracteristicas]="false"
  [index]="i"
></inmueble-card-inmueble>

  </div>
<div  *ngIf="resultados.length !== data.length" style="display:flex;margin:0 auto;width:100%;margin-top:2rem;">
  <a class="btn btn-primary" (click)="mostrarTodos()">{{verMas}}</a>
</div>
