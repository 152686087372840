import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedRoutingModule } from './shared-routing.module';
import { NavbarComponent } from './components/navbar/navbar.component';
import { FooterComponent } from './components/footer/footer.component';
import { InmuebleModule } from '../../../../../libs/inmueble/src';
import { WhatsappComponent } from './components/whatsapp/whatsapp.component';
import { TranslateModule } from '@ngx-translate/core';


@NgModule({
  declarations: [NavbarComponent, FooterComponent, WhatsappComponent],
  exports: [
    NavbarComponent,
    FooterComponent,
    WhatsappComponent,
  ],
  imports: [
    CommonModule,
    SharedRoutingModule,
    InmuebleModule,
    TranslateModule,
  ],
})
export class SharedModule { }
