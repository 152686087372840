<p-dialog header="Contacto"
  class="dialog-contactanos"
  [closable]="false"
  *ngIf=" exito"
  [(visible)]="exito"
  [modal]="true"
  [baseZIndex]="10000"
  [draggable]="false"
  [resizable]="false">
  <div *ngIf="error">
    <p class="p-m-0 --text-error">
      {{error}}
    </p>
  </div>
  <p><i class="pi pi-check-circle text-green-600" *ngIf="cita"></i>Se envio la información correctamente.</p>
  <p>En brevedad uno de nuestros agentes se pondra en contacto.</p>

  <div class="flex gap-4" *ngIf="!concedido">
    <img src="assets/img/notificaciones/notificacion.svg" alt="icon-notificacion" class="w-1/12">
    <div>
      <strong>Acepta las notificaciones y entérate de todo nuestros avisos</strong>
    </div>
  </div>

  <div class="flex flex-row-reverse mt-4 gap-2">
    <p-button
      type="submit"
      (click)="aceptar()"
      styleClass="p-button"
      label="Aceptar"></p-button>
  </div>
</p-dialog>
