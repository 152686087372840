import { NgModule } from '@angular/core'
import { BrowserModule, TransferState } from '@angular/platform-browser'
import { AppComponent } from './app.component'
import { InmuebleModule } from '@inmueble/inmueble'
import { AppRoutingModule } from './app-routing.module'
import { DropdownModule } from 'primeng/dropdown'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { InterceptorProvider } from '../../../../libs/auth/src/lib/sesion/interceptor/interceptor'
import { SharedModule } from './shared/shared.module'
import { StoreDevtoolsModule } from '@ngrx/store-devtools'
import { RouterState, StoreRouterConnectingModule } from '@ngrx/router-store'
import { StoreModule } from '@ngrx/store'
import { inmobiliaria } from './store'
import { NotifierModule } from 'angular-notifier'
import { PPage } from './p/p.page'
import { AdministradorComponent } from './administrador/administrador.component'
import { TranslateLoader, TranslateModule } from '@ngx-translate/core'
import { TranslateHttpLoader } from '@ngx-translate/http-loader'
import { HttpClient } from '@angular/common/http'
import { translateBrowserLoaderFactory } from './shared/loaders/translate-browser.loader'
import { PageSistemaComponent } from './page-sistema/page-sistema.component'
import { ForumularioGeneralModule } from './forumulario-general/forumulario-general.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment'

@NgModule({
  declarations: [AppComponent, PPage, AdministradorComponent, PageSistemaComponent],
  imports: [
    InmuebleModule,
    AppRoutingModule,
    DropdownModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: true,
    }),
    StoreRouterConnectingModule.forRoot({ routerState: RouterState.Minimal }),
    StoreModule.forRoot({ inmobiliaria }),
    NotifierModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: translateBrowserLoaderFactory,
        deps: [HttpClient, TransferState],
      },
    }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: true,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
    }),
  ],
  providers: [InterceptorProvider, TransferState],
  bootstrap: [AppComponent],
})
export class AppModule { }

// tslint:disable-next-line:typedef
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json')
}
