import { animate, state, style, transition, trigger } from '@angular/animations'
import { BreakpointObserver } from '@angular/cdk/layout'
import { DOCUMENT } from '@angular/common'
import { Component, ElementRef, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { TranslateService } from '@ngx-translate/core'
import { Subscription } from 'rxjs'
import { INavegador } from '@inmueble/inmueble'
import { AuthSesionLoginService } from '../../../../../../../libs/auth/src/lib/sesion/apis/login.service'
import { IUsuarioLogin } from '../../../../../../../libs/auth/types'
import { TokenService } from '../../../../../../../libs/inmueble/src/lib/apis/token.service'
import { ControlService } from '../../../navegador-control/apis/control.service'

const traducciones = [
  { es: '', en: '' },
  { es: 'servicios', en: 'services' },
  { es: 'productos', en: 'products' },
  { es: 'nosotros', en: 'about' },
  { es: 'blog', en: 'blog' },
  { es: 'blogs', en: 'blogs' },
  { es: 's-desarrollos', en: 's-developments' },
  { es: 's-desarrollo', en: 's-development' },
  { es: 'propiedades', en: 'properties' },
  { es: 'aviso-privacidad', en: 'privacy-notice' },
  { es: 'contacto-general', en: 'general-contact' },
]

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'inmueble-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  animations: [
    trigger('openClose', [
      // ...
      state(
        'open',
        style({
          right: '0',
        }),
      ),
      state(
        'closed',
        style({
          right: '-100%',
        }),
      ),
      transition('open => closed', [animate('0.3s')]),
      transition('closed => open', [animate('0.3s')]),
    ]),
  ],
})
export class NavbarComponent implements OnInit, OnDestroy {
  src = ''
  modalIniciaSesion = false
  menuMovil = false
  @ViewChild('hero', { static: true }) hero: ElementRef<HTMLDivElement>
  @Input() token
  @Output() onChange = new EventEmitter<INavegador>()
  user: IUsuarioLogin
  private db: IDBDatabase
  guardandoBusqueda = false
  suscripcion = false
  rutaAnterior = ''
  fechaExpiracion = new Date()
  movil: boolean
  navigationSubscriptionQuery: Subscription
  layoutChanges = this.breakpointObserver.observe(['(max-width: 767.98px)']).subscribe((result) => {
    this.movil = result.matches
  })
  activeLang
  idioma
  categoria
  tipoPropiedad

  ciudad = localStorage.getItem('ciudad') ? localStorage.getItem('ciudad') : 'mazatlan-sinaloa'

  idiomaActivo: Subscription = this.routerActivated.data.subscribe((data) => {
    this.idioma = data.lang ? data.lang : 'es'
    localStorage.setItem('lang', this.idioma)
    this.translate.setDefaultLang(this.idioma)
    this.document.documentElement.lang = this.idioma
  })

  constructor(
    private tokenService: TokenService,
    private authSesion: AuthSesionLoginService,
    private breakpointObserver: BreakpointObserver,
    private translate: TranslateService,
    private router: Router,
    private routerActivated: ActivatedRoute,
    @Inject(DOCUMENT) private document: Document,
    private navegadorService: ControlService,
  ) {}

  ngOnInit(): void {
    this.navegadorService.mostrarElemento$.subscribe((data) => {
      console.log(data, 'este es el número de modal que voy a mostrar')
    })
    this.tipoPropiedad = this.idioma === 'en' ? 'for-sale-in' : 'en-venta-en'

    if (this.authSesion.isAuth()) {
      this.sessionAuth()
    }

    const request = window.indexedDB.open('navegador_control', 1)
    request.onupgradeneeded = function (event) {
      const db: any = request.result
      const objectStore = db.createObjectStore('navegador_control', { keyPath: 'accionId' })
      objectStore.createIndex('accionId', 'accionId', { unique: true })
      objectStore.createIndex('canalId', 'canalId', { unique: false })
      objectStore.createIndex('concedido', 'concedido', { unique: false })
      objectStore.createIndex('count', 'count', { unique: false })
      objectStore.createIndex('rechazado', 'rechazado', { unique: false })
      objectStore.createIndex('rechazadoDiario', 'rechazadoDiario', { unique: false })
      objectStore.createIndex('valor', 'valor', { unique: false })
      objectStore.createIndex('notified', 'notified', { unique: false })
      objectStore.createIndex('fechaExpiracion', 'fechaExpiracion', { unique: false })
    }
    const tokenNavegador = localStorage.getItem('token')
    if (!tokenNavegador) {
      this.tokenService.post().subscribe((data) => {
        this.token = data
        localStorage.setItem('token', data.token)
        this.obtenerInformacion(data.token)
      })
    } else {
      this.obtenerInformacion(tokenNavegador)
    }
  }

  logout(): void {
    this.authSesion.unauth()
    this.user = null
  }

  sessionAuth(): void {
    this.user = null
    if (this.authSesion.isAuth()) {
      this.authSesion.current().subscribe((data: IUsuarioLogin) => (this.user = data))
    }
  }

  rutaLocal(seccion: string): string {
    const traduccion = traducciones.find((item) => item.es === seccion || item.en === seccion)
    return traduccion[this.idioma]
  }

  ruta(): string {
    const extraerSeccionRegex = /^\/[a-z-]+\/([^/\n]+)\/?/i
    const match = extraerSeccionRegex.exec(this.router.url)

    if (!match) return traducciones[0].en

    const urlActual = match[1]
    const idiomaUrl = this.idioma === 'en' ? 'es' : 'en'

    const traduccion = traducciones.find((item) => item[this.idioma] === urlActual)

    if (!traduccion) throw new Error('Traducción de sección no encontrada')

    return match['input'].replace(this.idioma, idiomaUrl).replace(traduccion[this.idioma], traduccion[idiomaUrl])
  }

  async cambiarLenguaje() {
    this.activeLang = this.idioma === 'es' ? 'en' : 'es'
    const path = this.ruta()
    const url = this.activeLang + (path ? `/${path}` : '')
    if (path.length) {
      await this.router.navigateByUrl(path)
    } else {
      await this.router.navigateByUrl(url)
    }
    window.scrollTo(0, 0)
  }

  modal() {
    this.modalIniciaSesion = false
    this.sessionAuth()
  }

  menuOpen() {
    if (this.movil) {
      const body = document.getElementById('body')
      this.menuMovil = !this.menuMovil
      body.style.position = this.menuMovil ? 'fixed' : 'unset'
    }
  }

  obtenerInformacion(token: string): void {
    this.tokenService.get(token).subscribe((response) => {
      this.IndexDB(response.control)
      this.onChange.emit(response)
    })
  }

  IndexDB(employeeData): void {
    let db
    const request: any = window.indexedDB.open('navegador_control', 1)
    request.onupgradeneeded = function (event) {
      const db: any = request.result
      const objectStore = db.createObjectStore('navegador_control', { keyPath: 'accionId' })
      objectStore.createIndex('accionId', 'accionId', { unique: true })
      objectStore.createIndex('canalId', 'canalId', { unique: false })
      objectStore.createIndex('concedido', 'concedido', { unique: false })
      objectStore.createIndex('count', 'count', { unique: false })
      objectStore.createIndex('rechazado', 'rechazado', { unique: false })
      objectStore.createIndex('rechazadoDiario', 'rechazadoDiario', { unique: false })
      objectStore.createIndex('valor', 'valor', { unique: false })
      objectStore.createIndex('notified', 'notified', { unique: false })
      objectStore.createIndex('fechaExpiracion', 'fechaExpiracion', { unique: false })
    }
    request.onsuccess = function (event) {
      db = event.target.result
      const data = db.transaction(['navegador_control'], 'readwrite')
      const object = data.objectStore('navegador_control')
      for (const i of employeeData) {
        i.fechaExpiracion = new Date()
        console.log(i,'aerAkiiiiiiiii')
        object.add(i)
      }
    }
    this.validacion()
  }

  validacion(): void {
    const indexDB: any = window.indexedDB.open('navegador_control', 1)
    indexDB.onsuccess = function (event) {
      const request = event.target.result
        .transaction(['navegador_control'], 'readwrite')
        .objectStore('navegador_control')
      const get = request.get(3)

      get.onsuccess = (event) => {
        const item = event.target.result
        const fechaExpiracion = new Date()
        if (item <= fechaExpiracion) {
          item.rechazadoDiario = this.rechazadoDiario = 0
          request.put(item)
        } else {
          this.guardandoBusqueda = true
        }
      }
    }
  }

  ngOnDestroy(): void {
    this.layoutChanges.unsubscribe()
    this.idiomaActivo.unsubscribe()
  }
}
