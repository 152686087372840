import { IsNumberString } from 'class-validator'

export class agenteDto{
  id?: number
  activo!: boolean
  master!:boolean
  ultimaConexion!: Date
  usuarioId!:number
}
export class AgenteUsuarioParamsDto {
  @IsNumberString()
  usuarioId!: number
}

export class AgenteParamsDto {
  @IsNumberString()
  agenteId!: number
}

export class AgenteUpdateDto {
  master!: boolean
}
export class AgentesInterfaceDto {
  id!: number | null
  master!: boolean
  nombre!: string
  usuarioId?: number
  busqueda?: string
}
export class UsuarioInterfaceDto {
  id!: number | null
  nombre!: string
}
