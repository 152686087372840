import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'inmobiliaria-page-sistema',
  templateUrl: './page-sistema.component.html',
  styleUrls: ['./page-sistema.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class PageSistemaComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
