<div class="resultado">
  <p>Resultado de <span>{{busqueda}}</span></p>
  <div class="filtrar">
    <p  (click)="OnModal()" >Filtrar <span class="icon-filtrar"></span></p>
  </div>
  <p-dropdown
    class="select"
    name="orden"
    [options]="dataOrden"
    [(ngModel)]="ordenId"
    placeholder="Ordenar por"
    optionLabel="descripcion"
    optionValue="descripcion"
    (onChange)="regresarInfo('order')">
    <option value="0" *ngFor="let item of dataOrden">{{item.descripcion}}</option>

  </p-dropdown>
  <div class="ordenCards">
    <a (click)="horizontal=true"><span [ngClass]="{'icon-active':horizontal}" class="icon-horizontal"></span></a>
    <a (click)="horizontal=false"><span [ngClass]="{'icon-active':!horizontal}" class="icon-vertical"></span></a>
  </div>
</div>
