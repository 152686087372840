import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class InmuebleCatalogoMotivoService {

  constructor(@Inject(HttpClient) private http: HttpClient) {
  }
  get(): Observable<any> {
    return this.http.get<any>('/api/inmuebles/catalogos/motivo');
  }
}
