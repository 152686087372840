<div class="container-grid">
  <div class="main">
    <div>
      <div class="resultado" *ngIf="!administrador">
        <p *ngIf="resultados">
          {{ resultadoText }}<span>{{ busqueda }}</span>
        </p>
        <div class="filtrar">
          <p (click)="OnModal()">Filtrar <span class="icon-filtrar"></span></p>
        </div>
        <p-dropdown
          *ngIf="data.order"
          [readonly]="!data.length"
          class="select"
          name="orden"
          [options]="dataOrden"
          [(ngModel)]="ordenId"
          placeholder="Ordenar por"
          optionLabel="titulo"
          optionValue="descripcion"
          (onChange)="regresarInfo('order')"
        >
          <option value="0" *ngFor="let item of dataOrden">{{ item.descripcion }}</option>
        </p-dropdown>
        <div class="ordenCards">
          <a (click)="horizontal = false"
            ><span [ngClass]="{ 'icon-active': !horizontal }" class="icon-vertical"></span
          ></a>
          <a (click)="horizontal = true"
            ><span [ngClass]="{ 'icon-active': horizontal }" class="icon-horizontal"></span
          ></a>
        </div>
      </div>

      <div
        [hidden]="data && !data.length"
        [ngClass]="{ 'card-grid ': !horizontal, 'card-horizontal-grid': horizontal }"
        #div
      >
        <inmueble-card-inmueble
          class="inmuebles-lista"
          *ngFor="let item of data; let i = index"
          [item]="item"
          [descripcion]="descripcion"
          [index]="i"
          [admin]="administrador"
          [info]="true"
          [caracteristicas]="true"
          [etiquetas]="etiqueta"
          [horizontal]="horizontal"
          [infoDentro]="false"
          [precioDentro]="precioDentro"
          [caracteristicasDentro]="false"
          (delete)="eliminarItem($event)"
        >
        </inmueble-card-inmueble>
      </div>
      <p-paginator
        *ngIf="!administrador && data.length"
        [style]="{ background: 'unset' }"
        [rows]="limite"
        [totalRecords]="totalData"
        (onPageChange)="onPageChange($event)"
      ></p-paginator>
      <div class="no-resultados" *ngIf="data && !data.length">
        <img src="assets/img/sin-resultados.png" alt="Sin resultados" />
        <h3>{{ noResultadoText }}</h3>
      </div>
    </div>
  </div>
</div>
