import { Component, Input, OnInit } from '@angular/core'

@Component({
  selector: 'inmueble-mapa',
  templateUrl: './mapa.component.html',
  styleUrls: ['./mapa.component.scss'],
})
export class MapaComponent implements OnInit {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  google = google
  geocoder = new this.google.maps.Geocoder()
  @Input() direccion: string
  @Input() zoom: number
  lat
  lng

  constructor() {}

  getLocation(address: string): Promise<any> {
    const google = this.google

    return new Promise((resolve, reject) => {
      // @ts-ignore
      this.geocoder.geocode(
        { address },
        function (results: { geometry: { location: { lat: Function; lng: Function } } }[], status: number) {
          // @ts-ignore
          if (status === google.maps.GeocoderStatus.OK) {
            const geometria = results[0].geometry
            resolve({ lat: geometria.location.lat(), lng: geometria.location.lng() })
          } else {
            reject(null)
          }
        },
      )
    })
  }

  ngOnInit(): void {
    this.getLocation(this.direccion).then((value) => {
      this.lat = value.lat
      this.lng = value.lng
    })
  }
}
