export class nose {
  hola!: string
}

export class FiltroCuentasDto {
  id!: number | null
  plataformaId!: number | null
  descripcion!: string
  alias?: string
}

export class FiltroPlataformasDto {
  id!: number
  descripcion!: string
  prospectos!: number[]
  cuenta!: FiltroCuentasDto
}

export class FiltroCuentasResponseDto {
  cuenta!: FiltroCuentasDto[]
  current!: any
}

export class FiltroActividadDto {
  actividades!: any
  cumple!: FiltroActividadCumpleanioDto
  etiquetas!: any
  clasificacion!: any
  opcional!: boolean
}

export class FiltroActividadCumpleanioDto {
  fechaInicial!: string
  fechaFinal!: string
}

export class FiltroActividadesDto {
  actividad!: ActividadFiltroDto
  fechaInicial!: number
  fechaFinal!: number
}

export class ActividadFiltroDto {
  id!: any
  tipo!: string
  descripcion!: string
}

export class FiltroProspectoPlataformaDto {
  prospectoId!: number
  plataformaId!: number[]
}
