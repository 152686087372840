import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { AdministradorComponent } from './administrador/administrador.component'
import { PPage } from './p/p.page'
import { PageSistemaComponent } from './page-sistema/page-sistema.component'
import { AvisoPrivacidadComponent } from './principal/components/principal/aviso-privacidad/aviso-privacidad.component'

const routes: Routes = [
  {
    path: 'es',
    component: PPage,
    data: { lang: 'es' },
    children: [
      {
        path: '',
        loadChildren: () => import('./principal/principal.module').then((m) => m.PrincipalModule),
      },
      {
        path: 's-desarrollos/:ciudad',
        loadChildren: () => import('./lista-desarrollo/lista-desarrollo.module').then((m) => m.ListaDesarrolloModule),
      },
      {
        path: 's-desarrollo/:ciudad',
        loadChildren: () => import('./desarrollo/desarrollo.module').then((m) => m.InmobiliariaDesarrolloModule),
      },
      {
        path: 'propiedades/:categoria/:ciudad',
        children: [
          {
            path: '',
            loadChildren: () => import('./lista-inmueble/lista-inmueble.module').then((m) => m.listaInmuebleModule),
          },
          {
            path: ':titulo/:codigoInmueble',
            loadChildren: () =>
              import('./inmobiliaria-inmueble/inmobiliaria-inmueble.module').then((m) => m.InmobiliariaInmuebleModule),
          },
        ],
      },
      {
        path: 'servicios',
        loadChildren: () => import('./servicios/servicios.module').then((m) => m.ServiciosModule),
      },
      {
        path: 'nosotros',
        loadChildren: () => import('./nosotros/nosotros.module').then((m) => m.NosotrosModule),
      },
      {
        path: 'blogs/administrador',
        loadChildren: () => import('@decet/browser-browser').then((m) => m.BlogsBrowserPrivadoModule),
      },
      {
        path: 'blog/administrador',
        loadChildren: () => import('@decet/browser-browser').then((m) => m.BlogsBrowserPrivadoModule),
      },
      {
        path: 'blog',
        loadChildren: () => import('./blog/blog.module').then((m) => m.InmobiliariaBlogModule),
      },
      {
        path: 'blogs',
        loadChildren: () => import('./lista-blog/lista-blog.module').then((m) => m.InmobiliariaListaBlogModule),
      },
      {
        path: 'session',
        loadChildren: () => import('libs/auth/src/lib/sesion/sesion.module').then((m) => m.AuthSesionModule),
      },
      {
        path: 'aviso-privacidad',
        component: AvisoPrivacidadComponent,
      },
      {
        path: 'contacto-informacion/:patrocinio',
        loadChildren: () =>
          import('apps/inmobiliaria/src/app/forumulario-general/forumulario-general.module').then(
            (m) => m.ForumularioGeneralModule,
          ),
      },
    ],
  },
  {
    path: '',
    component: PPage,
    data: { lang: 'es' },
    children: [
      {
        path: '',
        loadChildren: () => import('./principal/principal.module').then((m) => m.PrincipalModule),
      },
    ],
  },
  {
    path: '',
    component: PageSistemaComponent,
    data: { lang: 'es' },
    children: [
      {
        path: 'crm',
        loadChildren: () => import('libs/crm-libreria/src/lib/crm.module').then((m) => m.CrmModule),
      },
    ],
  },
  {
    path: '',
    component: PPage,
    data: { lang: 'es' },
    children: [
      {
        path: '',
        loadChildren: () => import('./principal/principal.module').then((m) => m.PrincipalModule),
      },
      {
        path: 's-desarrollos/:ciudad',
        loadChildren: () => import('./lista-desarrollo/lista-desarrollo.module').then((m) => m.ListaDesarrolloModule),
      },
      {
        path: 's-desarrollo/:ciudad',
        loadChildren: () => import('./desarrollo/desarrollo.module').then((m) => m.InmobiliariaDesarrolloModule),
      },
      {
        path: 'propiedades/:categoria/:ciudad',
        children: [
          {
            path: '',
            loadChildren: () => import('./lista-inmueble/lista-inmueble.module').then((m) => m.listaInmuebleModule),
          },
          {
            path: ':titulo/:codigoInmueble',
            loadChildren: () =>
              import('./inmobiliaria-inmueble/inmobiliaria-inmueble.module').then((m) => m.InmobiliariaInmuebleModule),
          },
        ],
      },
      {
        path: 'servicios',
        loadChildren: () => import('./servicios/servicios.module').then((m) => m.ServiciosModule),
      },
      {
        path: 'nosotros',
        loadChildren: () => import('./nosotros/nosotros.module').then((m) => m.NosotrosModule),
      },
      {
        path: 'blogs/administrador',
        loadChildren: () => import('@decet/browser-browser').then((m) => m.BlogsBrowserPrivadoModule),
      },
      {
        path: 'blog/administrador',
        loadChildren: () => import('@decet/browser-browser').then((m) => m.BlogsBrowserPrivadoModule),
      },
      {
        path: 'blog',
        loadChildren: () => import('./blog/blog.module').then((m) => m.InmobiliariaBlogModule),
      },
      {
        path: 'blogs',
        loadChildren: () => import('./lista-blog/lista-blog.module').then((m) => m.InmobiliariaListaBlogModule),
      },
      {
        path: 'session',
        loadChildren: () => import('libs/auth/src/lib/sesion/sesion.module').then((m) => m.AuthSesionModule),
      },
      {
        path: 'aviso-privacidad',
        component: AvisoPrivacidadComponent,
      },
      {
        path: 'contacto-informacion/:patrocinio',
        loadChildren: () =>
          import('apps/inmobiliaria/src/app/forumulario-general/forumulario-general.module').then(
            (m) => m.ForumularioGeneralModule,
          ),
      },
    ],
  },
  {
    path: 'en',
    component: PPage,
    data: { lang: 'en' },
    children: [
      {
        path: '',
        loadChildren: () => import('./principal/principal.module').then((m) => m.PrincipalModule),
      },
      {
        path: 's-developments/:ciudad',
        loadChildren: () => import('./lista-desarrollo/lista-desarrollo.module').then((m) => m.ListaDesarrolloModule),
      },
      {
        path: 's-development/:ciudad',
        loadChildren: () => import('./desarrollo/desarrollo.module').then((m) => m.InmobiliariaDesarrolloModule),
      },
      {
        path: 'properties/:categoria/:ciudad',
        children: [
          {
            path: '',
            loadChildren: () => import('./lista-inmueble/lista-inmueble.module').then((m) => m.listaInmuebleModule),
          },
          {
            path: ':titulo/:codigoInmueble',
            loadChildren: () =>
              import('./inmobiliaria-inmueble/inmobiliaria-inmueble.module').then((m) => m.InmobiliariaInmuebleModule),
          },
        ],
      },
      {
        path: 'services',
        loadChildren: () => import('./servicios/servicios.module').then((m) => m.ServiciosModule),
      },
      {
        path: 'about',
        loadChildren: () => import('./nosotros/nosotros.module').then((m) => m.NosotrosModule),
      },

      {
        path: 'blogs/administrador',
        loadChildren: () => import('@decet/browser-browser').then((m) => m.BlogsBrowserPrivadoModule),
      },
      {
        path: 'blog/administrador',
        loadChildren: () => import('@decet/browser-browser').then((m) => m.BlogsBrowserPrivadoModule),
      },
      {
        path: 'blog',
        loadChildren: () => import('./blog/blog.module').then((m) => m.InmobiliariaBlogModule),
      },
      {
        path: 'blogs',
        loadChildren: () => import('./lista-blog/lista-blog.module').then((m) => m.InmobiliariaListaBlogModule),
      },
      {
        path: 'session',
        loadChildren: () => import('libs/auth/src/lib/sesion/sesion.module').then((m) => m.AuthSesionModule),
      },
      {
        path: 'crm',
        loadChildren: () => import('libs/crm-libreria/src/lib/crm.module').then((m) => m.CrmModule),
      },
      {
        path: 'notice-of-privacy',
        component: AvisoPrivacidadComponent,
      },
      {
        path: 'contact-information/:patrocinio',
        loadChildren: () =>
          import('apps/inmobiliaria/src/app/forumulario-general/forumulario-general.module').then(
            (m) => m.ForumularioGeneralModule,
          ),
      },
    ],
  },
  {
    path: '',
    component: AdministradorComponent,
    data: { lang: 'es' },
    children: [
      {
        path: '',
        loadChildren: () => import('./usuarios/usuarios.module').then((m) => m.UsuariosModule),
      },
      {
        path: 'dashboard',
        children: [
          {
            path: '',
            loadChildren: () =>
              import('libs/real-estate/src/lib/dashboard/dashboard.module').then((m) => m.DashboardModule),
          },
        ],
      },
      {
        path: 'desarrollos',
        loadChildren: () =>
          import('libs/real-estate/src/lib/desarrollos/desarrollos/desarrollos.module').then(
            (m) => m.RealEstateDesarrollosModule,
          ),
      },
      {
        path: 'desarrollo',
        loadChildren: () =>
          import('libs/real-estate/src/lib/desarrollos/desarrollo/desarrollo.module').then(
            (m) => m.RealEstateDesarrolloModule,
          ),
      },
      {
        path: 'propiedades',
        loadChildren: () =>
          import('libs/real-estate/src/lib/inmuebles/inmuebles/inmuebles.module').then(
            (m) => m.RealEstateInmueblesModule,
          ),
      },
      {
        path: 'propiedad',
        loadChildren: () =>
          import('libs/real-estate/src/lib/inmuebles/inmueble/inmueble.module').then((m) => m.RealEstateInmuebleModule),
      },
      {
        path: 'blogs/administrador',
        loadChildren: () => import('@decet/browser-browser').then((m) => m.BlogsBrowserPrivadoModule),
      },
      {
        path: 'blog/administrador',
        loadChildren: () => import('@decet/browser-browser').then((m) => m.BlogsBrowserPrivadoModule),
      },
      {
        path: 'agrupaciones/admin',
        loadChildren: () =>
          import('../../../../libs/blogs/browser/src/lib/privado/catalogo-agrupacion/catalogo-agrupacion.module').then(
            (m) => m.CatalogoAgrupacionModule,
          ),
      },
    ],
  },
  {
    path: 'templates',
    loadChildren: () => import('@decet/templates-browser').then((m) => m.TemplatesBrowserModule),
  },
  {
    path: '**',
    loadChildren: () => import('./error404/error404.module').then((m) => m.Error404Module),
  },
]

@NgModule({
  declarations: [],
  imports: [CommonModule, RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
