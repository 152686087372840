import { Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, Output } from '@angular/core';
import { IInmueble } from '../../interface';
import { InmuebleService } from '../apis/inmueble.service';

@Component({
  selector: 'inmueble-card-inmueble',
  templateUrl: './card-inmueble.component.html',
  styleUrls: ['./card-inmueble.component.scss']
})

export class CardInmuebleComponent implements OnChanges {
  @Input() info = true;
  @Input() caracteristicas = true;
  @Input() etiquetas = true;
  @Input() infoDentro = false;
  @Input() caracteristicasDentro = false;
  @Input() horizontal = false;
  @Input() horizontalSmall = false;
  @Input() index: number;
  @Input() tituloDentro = false;
  @Input() item: IInmueble | null = null;
  @Input() url: string;
  @Input() blog: false;
  @Input() data: IInmueble;
  @Input() ubicacion = true;
  @Input() precioDentro = false;
  @Input() descripcion = false;
  userAgent = navigator.userAgent;
  movil = false;
  target = '';
  state = '';
  idAEliminar = null;
  imagenCargada = false;
  @Input() admin = false;
  @Output() delete = new EventEmitter<number>();

  constructor(public el: ElementRef) {
  }

  @HostListener('window:scroll', ['$event'])
  checkScroll() {
    const componentPosition = this.el.nativeElement.offsetTop;
    const scrollPosition = window.pageYOffset;

    if (scrollPosition >= componentPosition) {
      this.state = 'scrolled';
    } else {
      this.state = 'normal';
    }

  }

  ngOnChanges(): void {
    if (this.userAgent.match(/Android/i) || this.userAgent.match(/webOS/i) || this.userAgent.match(/iPhone/i)
      || this.userAgent.match(/iPad/i) || this.userAgent.match(/iPod/i) || this.userAgent.match(/BlackBerry/i)
      || this.userAgent.match(/Windows Phone/i)) {
      this.movil = true;
    }
    this.target = this.movil ? '_self' : '_black';
  }


  eliminar(inmuebleId): void {
    this.idAEliminar = inmuebleId;
  }


  eliminarResponse(eliminado: boolean): void {
    if (eliminado) {
      this.delete.emit(this.idAEliminar);
    }
    this.idAEliminar = null;
  }
}
