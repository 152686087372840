import { AgmCoreModule } from '@agm/core'
import { CommonModule } from '@angular/common'
import { HttpClientModule } from '@angular/common/http'
import { NgModule } from '@angular/core'
import { FormsModule } from '@angular/forms'
import { MessageService } from 'primeng/api'
//import { AdministradorRoutingModule } from '../../../../apps/inmobiliaria/src/app/administrador/administrador-routing.module';
import { ButtonModule } from 'primeng/button'
import { CalendarModule } from 'primeng/calendar'
import { CheckboxModule } from 'primeng/checkbox'
import { DialogModule } from 'primeng/dialog'
import { DropdownModule } from 'primeng/dropdown'
import { PaginatorModule } from 'primeng/paginator'
import { ToastModule } from 'primeng/toast'
import { CardInmuebleComponent } from './card-inmueble/card-inmueble.component'
import { EliminarConfirmarComponent } from './card-inmueble/confirmar/confirmar.component'
import { CardInmueble2Component } from './card-inmueble2/card-inmueble2.component'
import { GreetingsComponent } from './greetings/greetings.component'
import { InicioSesionComponent } from './inicio-sesion/inicio-sesion.component'
import { InmublesComponent } from './inmubles/inmubles.component'
import { InmueblesResultadosComponent } from './inmubles/inmuebles-resultados/inmuebles-resultados.component'
import { InmuebleSimilaresComponent } from './inmueble-similares/inmueble-similares.component'
import { InmuebleConfirmarContactanosComponent } from './inmueble/contactanos/confirmar/confirmar.component'
import { ContactanosComponent } from './inmueble/contactanos/contactanos.component'
import { MapaComponent } from './inmueble/mapa/mapa.component'

@NgModule({
  imports: [
    HttpClientModule,
    DropdownModule,
    FormsModule,
    CommonModule,
    PaginatorModule,
    CalendarModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyAYOJ3nOwuVKJoYUO-6ujVK2IKZL2kGBS8',
    }),
    //  AdministradorRoutingModule,
    ButtonModule,
    DialogModule,
    ToastModule,
    CheckboxModule,
  ],
  declarations: [
    GreetingsComponent,
    ContactanosComponent,
    InicioSesionComponent,
    InmublesComponent,
    CardInmuebleComponent,
    CardInmueble2Component,
    InmuebleSimilaresComponent,
    InmueblesResultadosComponent,
    EliminarConfirmarComponent,
    MapaComponent,
    InmuebleConfirmarContactanosComponent,
  ],
  exports: [
    GreetingsComponent,
    ContactanosComponent,
    InicioSesionComponent,
    InmublesComponent,
    CardInmuebleComponent,
    CardInmueble2Component,
    InmuebleSimilaresComponent,
    InmuebleConfirmarContactanosComponent,
    MapaComponent,
  ],
  providers: [MessageService],
})
export class InmuebleModule {}
