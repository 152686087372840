<a class="card"
  [ngClass]="{'card-horizontal': horizontal}"
  [href]="item.url"
  *ngIf="item">
  <div [ngClass]="{'horizontal' : horizontal,'vertical':!horizontal}" style="height:fit-content">
    <div class="img-wrapper">

      <img  loading="lazy" [ngClass]="{'img-dentro':infoDentro || (!infoDentro && caracteristicasDentro)}"
        [src]="item.multimedia[0].url" alt="img card"
        class="card-img-top">
      <div class="etiquetas" *ngIf="etiquetas">
        <div>

          <span class="badge badge-primary">{{item.tipo ? item.tipo.descripcion : ''}}</span>
          <span class="badge badge-blue">Nuevo</span>
          <span class="badge badge-red">Hot</span>
        </div>
      </div><!--Fin etiquetas-->

      <!--  boton eliminar admin-->
      <div class="etiquetas" *ngIf="admin">
        <div class="p-buttonset">
          <span
            class="badge badge-red"
            (click)="eliminar(item.id);$event.preventDefault()">Eliminar
          </span>

        </div>
      </div><!--Fin boton eliminar adnin-->

      <!--solo info caracteristicas-->
      <div class="info-dentro" *ngIf="!infoDentro && caracteristicasDentro">
        <div>
          <div class="caracteristicas" style="float:right">
            <div>
                  <span *ngFor="let car of item.caracteristicas;" title="{{car.caracteristica}}">
                    <i class="{{car.icon}}"></i>{{car.cantidad}}
              </span>
            </div>
          </div>
        </div>
      </div><!--fin caracteristicas dentro-->
      <div class="info-dentro" *ngIf="!infoDentro && precioDentro">
        <h5 class="precio">{{item.precio | currency}}</h5>
      </div>
      <div class="info-dentro" *ngIf="item.unidadesDisponibles && !horizontal">
        <span *ngIf="item.unidadesDisponibles>0" class="unidadesDisponibles">{{item.unidadesDisponibles  }} disponibles</span>
        <span *ngIf="item.unidadesDisponibles<=0" class="unidadesDisponibles red" >Agotado</span>
      </div>
      <div class="info-dentro w-100" *ngIf="tituloDentro">
        <h3 class="titulo-dentro">{{item.descripcion | titlecase}}</h3>
      </div>
      <!--Info dentro de img-->
      <div class="informacion-dentro" *ngIf="infoDentro">

        <div class="contenedor-precio">
          <h6 class="precio-dentro">{{item.precio | currency}}</h6>
        </div>
        <div *ngIf="item.ciudad && ubicacion">
            <span>
              <i class="fa fa-map-marker-alt"></i>
              {{item.ciudad.descripcion}}
            </span>
        </div>
        <div class="contenedor-inferior">
          <div class="contenedor-caracteristicas">
            <h5 class="card-title --text-clip">{{item.titulo}}</h5>
            <div *ngIf="caracteristicasDentro" class="caracteristicas">
              <div>
              <span *ngFor="let car of item.caracteristicas;" title="{{car.caracteristica}}">
                    <i class="{{car.icon}}"></i>{{car.cantidad}}
              </span>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
  <div *ngIf="!infoDentro" [ngClass]="{'horizontal-body' : horizontal,'vertical':!horizontal}" style="height: fit-content;">
    <div class="card-body " *ngIf="info">
      <h4 class="precio" *ngIf="item.precio">{{item.precio | currency}}</h4>
      <div class="ubicacion" *ngIf="item.ciudad && ubicacion">
         <span>
           <i class="fa fa-map-marker-alt"></i>{{item.ciudad.descripcion}}
         </span>
      </div>

      <div *ngIf="caracteristicas && !admin &&item.caracteristicas" class="caracteristicas">
        <div>
           <span *ngFor="let car of item.caracteristicas;" title="{{car.caracteristica}}">
           <i class="{{car.icon}}"></i>{{car.cantidad}}
              </span>
        </div>
      </div>

      <h5 class="card-title --text-clip">{{item.titulo}}</h5>
      <p *ngIf="desarrollo" class="--text-clip-large disponibles">{{item.unidadesDisponibles}} disponibles</p>
      <p *ngIf="item.introduccion" class="--text-clip-large">{{item.introduccion}}</p>
      <p *ngIf="horizontal || descripcion" class="--text-clip-large tres desc">{{item.descripcion}}</p>


    </div>
  </div>
</a>
<inmueble-eliminar-confirmar
  *ngIf="idAEliminar"
  [id]="idAEliminar"
  (cerrar)="eliminarResponse($event)"></inmueble-eliminar-confirmar>
