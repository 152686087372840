export interface IUsuarioPermisosAgrupacion {
  id: number
  descripcion: string
}

export type IUsuarioPermisos = IUsuarioPermiso[]

export interface IAgrupacionUsuarioPermisos {
  agrupacion: IUsuarioPermisosAgrupacion
  items: IUsuarioPermisos
}

export type IAgrupacionesUsuarioPermisos = IAgrupacionUsuarioPermisos[]

export interface IUsuarioPermiso {
  id: number
  descripcion: string
  agrupacion: IUsuarioPermisosAgrupacion
  seleccionado: boolean
  eliminado: boolean
  modificado: boolean
}

export interface IUsuarioMiddleWare {
  id: number
  dominioId: number
  companiaId: number
  empresaId: number
  master: boolean
  username: string
  nombre: string
  apellidos: string
  activo: boolean
  modulos: ISessionModulosPermisos[]
  modulosDominio: ISessionModulosPermisos
  modulosEmpresa: ISessionModulosPermisos
}

export interface ISessionModulosPermiso {
  id: number
  empresaId: number | null
  codigo: string,
  descripcion: string
  menu: string | null
  menuIcon: string | null
  seccionId: number
  titulo: string
  icon: string
  url: string
}

export type ISessionModulosPermisos = ISessionModulosPermiso[]
export type IUsuario = {
  id?: number | null
  username: string | null
  correo: string
  numeroCelular: string
  nombre: string
  apellidos: string
  duracion: number | null
  salt: string | null
  hash: string | null
  aceptoTerminosServicio: boolean | null
  capturaUsuarioId: number | null
  capturaFecha: Date | null
  modificacionUsuarioId: number | null
  modificacionFecha: Date | null
  modulos?: any
  dominios?: IUsuarioDominios | null
};

export interface IAuthUsuariosUsuarioInformacion {
  id?: number
  nombre: string
  apellidos: string
  numeroCelular: string
  correo: string
}

export interface IAuthUsuariosUsuarioEstatus {
  estatus: boolean
  dominio: IDominio
}

export type IUsuarioAgregar = {
  id?: number
  duracion: number
  username: string
  correo: string
  nombre: string
  apellidos: string
  numeroCelular: string
  master: boolean
};

export type  IUsuariosAgregar = IUsuarioAgregar [];

export type IDominio = {
  id?: number
  nombre?: string
}

export type IUsuarioDominio = {
  id?: any
  master: boolean | null
  activo: boolean | null
  usuarioId: number | null
  eliminado: boolean | null
  dominio: IDominio | null
}

export type IUsuarioDominios = IUsuarioDominio[]

export type IUsuarioConfiguracionRestablecerReponse = {
  enviado: boolean
  error: string
}

export type IConfiguracion = {
  restablecer: boolean
  eliminar: boolean
  respuesta: string
};

export type IConfiguraciones = IConfiguracion[];

export type IEmpresa = {
  id?: number
  descripcion: string
  nombre: string
  busqueda?: string
};
export type IEmpresas = IEmpresa[];

export type IEmpresaPermiso = {
  id?: number
  nombre?: string
  descripcion: string
  permiso: boolean
  busqueda?: string
};

export type IEmpresaPermisos = Array<IEmpresaPermiso>;

export type IEmpresaPermisoFiltrado = {
  dominioId?: number
  empresaId?: []
  id?: number
  nombre?: string
  nombreCorto?: string
  razonSocial?: string
  rfc?: string
};

export type IEmpresaPermisoFiltrados = Array<IEmpresaPermisoFiltrado>;

export type  IModulo = {
  id?: number
  aplicacionId: number
  aplicacion: string
  modulo: string
};
export type IModulos = IModulo[];


//////////////////////////////////////////////////

export type IUsuarioEmpresaModulo = {
  id?: number
  aplicacionId?: number
  modulos?: IModulo2
  nombre: string
  titulo: string
};

export type IUsuairoEmpresaModulos = IUsuarioEmpresaModulo[];

export interface IModulo2 {
  id: number;
  dominioId: number;
  moduloId: number;
  usuarioId: number;
  editable: boolean;
}

export interface IUsuarioModuloAgrupacion {
  id?: number;
  aplicacionId?: number;
  modulos?: IModulo2;
  modulo: string;
  nombre: string;
  titulo: string;
  self: string[];
  editable: boolean;
}

export type IUsuarioModuloAgrupaciones = IUsuarioModuloAgrupacion[];

export type IUsuarioModuloPermisos = {
  id: number;
};

/*      SESION            */

export type ICredecial = {
  username: string
  password: string
};

export type ICredecialRespuesta = {
  dominioId: number;
  token: string;
};

export type IReestablecer = {
  email: string;
};

export type IRegistro = {
  password: string;
  aceptoTerminosServicio: boolean;
  activo: boolean;
  alias: string;
  nombre: string;
  empresa: string;
};

export type IUsuarioLogin = {
  id: number
  admin: boolean
  nombre: string
};

export type IUsuarioCambiar = {
  activo: boolean;
  alias: string;
  nombre: string;
  empresa: string;
  password: string;
};



