import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { InmuebleService } from '../../apis/inmueble.service';
import { InmuebleCatalogoMotivoService } from '../../apis/catalogo/motivo.service';

@Component({
  selector: 'inmueble-eliminar-confirmar',
  templateUrl: './confirmar.component.html',
  styleUrls: ['./confirmar.component.scss']
})
export class EliminarConfirmarComponent implements OnInit {

  displayModal: boolean;
  procesando: boolean;
  error: any;
  exito: boolean;
  motivo = [];
  motivoId: number | null = null;
  @Input() id: number;
  @Output() cerrar: EventEmitter<boolean> = new EventEmitter();

  constructor(
    private inmuebleService: InmuebleService,
    private motivoServie: InmuebleCatalogoMotivoService) {
    this.displayModal = true;
    this.procesando = false;
    this.error = null;
    this.exito = false;
  }

  ngOnInit() {
    this.motivoServie.get().toPromise()
      .then((data) => {
        this.motivo = data;
      });
  }

  finalizar(): void {
    this.displayModal = false;
    this.procesando = false;
    this.cerrar.emit(this.exito);
  }

  guardar(): void {
    if (this.procesando) {
      return;
    }

    this.procesando = true;
    this.inmuebleService.delete(this.id, this.motivoId).toPromise()
      .then(() => {
        this.exito = true;
      })
      .catch((error) => (this.error = error));
  }

}
