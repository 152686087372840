import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'inmueble-contactanos-confirmar',
  templateUrl: './confirmar.component.html',
  styleUrls: ['./confirmar.component.scss'],
})
export class InmuebleConfirmarContactanosComponent {
  @Input() cita = false;
  @Input() exito = false;
  @Input() error;
  @Output() respuesta = new EventEmitter<void>();
  checked = false;
  concedido!: boolean;

  aceptar() {
    if (this.checked) {
     this.concedido = true;
    }
    this.exito = false;
    this.respuesta.emit();
  }
}
