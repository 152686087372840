<header class="header contenedor"
  id="header">
  <div class="menu-bar">
    <a [routerLink]="['/',idioma]" >
      <img src="assets/img/logo_pacific_obscuro.png"
        width="150px"
        alt="Logo Pacific and Caribbean">
    </a>
    <a class="btn-menu"
      (click)="menuOpen()">
      <span><i class="fas" [ngClass]="{'fa-bars':!menuMovil, 'fa-times':menuMovil}"></i>
      </span>
    </a>
  </div>
  <div class="logo">
    <a  [routerLink]="['/',idioma]"><img src="assets/img/logo_pacific_obscuro.png" width="120px"
      alt="Logo Pacific and Caribbean"></a>

  </div>
  <nav [@openClose]="menuMovil ? 'open' : 'closed'" #nav id="nav" class="navegacion">
    <a [routerLink]="['/',idioma]" (click)="menuOpen()">{{'nav.inicio' | translate}}</a>
    <a [routerLink]="['/',idioma,rutaLocal('propiedades'),tipoPropiedad,ciudad]" (click)="menuOpen()"
      target="_blank">{{'nav.propiedades' | translate}}</a>
    <a [routerLink]="['/',idioma,rutaLocal('s-desarrollos'),ciudad]" (click)="menuOpen()"
      target="_blank">{{'nav.desarrollos' | translate}}</a>
    <a [routerLink]="['/',idioma, rutaLocal('blogs')]" (click)="menuOpen()">{{'nav.blog' | translate}}</a>
    <a [routerLink]="['/',idioma, rutaLocal('nosotros')]" (click)="menuOpen()">{{'nav.nosotros' | translate}}</a>
    <a [routerLink]="['/',idioma, rutaLocal('servicios')]" (click)="menuOpen()">{{'nav.servicios' | translate}}</a>
    <a role="button" class="cambiar-idioma" (click)="cambiarLenguaje();menuOpen()">{{'nav.idioma' | translate}}</a>

    <a [routerLink]="['/dashboard']" (click)="menuOpen()" *ngIf="user">{{'nav.admin' | translate}}</a>
    <a *ngIf="!user" (click)="menuOpen();modalIniciaSesion=true"> <span><i class="fa fa-user"></i></span></a>
    <a *ngIf="user" (click)="logout()"><span><i class="pi pi-sign-out"></i></span>{{user.nombre}}</a>
  </nav>
</header>
<inmueble-inicio-sesion
  *ngIf="modalIniciaSesion"
  (cierrate)="modal()">
</inmueble-inicio-sesion>
