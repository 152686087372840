import { IsNumber, IsString } from 'class-validator'

export class notasParamsDto {
  @IsNumber()
  prospectoId!: number
}

export class notasDto {
  fechaNota!: string
  @IsNumber()
  agenteId!: number
  @IsNumber()
  prospectoId!: number
  @IsString()
  nota!: string
}
