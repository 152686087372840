import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { IContacto } from '../../../interface';
import { ContactoService } from '../../apis/contacto.service';
import { NgForm } from '@angular/forms';
import { TokenService } from '../../apis/token.service';

@Component({
  selector: 'inmueble-contactanos',
  templateUrl: './contactanos.component.html',
  styleUrls: ['./contactanos.component.scss'],
})
export class ContactanosComponent implements OnInit {
  @Input() cita = false;
  @Input() correoI;
  @Input() whatsapp;
  @Input() mensaje = 'Deseo información';
  @Input() tipo!: number;
  @Input() telefonoI;
  @Input() nombreInmueble;
  @Input() cuerpo: string;
  @Input() idioma;
  @Input() placeholder;
  @Input() modal = true;
  patron = `^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$`;
  @Output() confirmacion = new EventEmitter<boolean>();
  url;
  dates: Date[];
  rangeDates: Date[];
  minDate: Date;
  maxDate: Date;
  es: any;
  invalidDates: Array<Date>;
  contacto: IContacto = {
    nombre: '',
    correo: '',
    telefono: '',
    mensaje: '',
    tipo: null,
    url: '',
    inmueble: '',
  };
  displayModal;
  error;
  procesando;
  exito;
  indexDB!: any;
  private notification;
  checked = false;
  concedido!: boolean;
  nombrePlaceHolder;
  telefonoPlaceHolder;
  correoPlaceHolder;
  fechaPlaceHolder;
  nombreRequerido;
  correoRequerido;
  fechaRequerido;
  telefonoRequerido;
  mensajeRequerido;
  opcionesContacto;
  botonContacto;
  botonCita;

  constructor(
    private contactoService: ContactoService,
    private tokenService: TokenService,
    private router: Router) {
  }

  ngOnInit(): void {
    if(this.idioma==='en'){
      this.nombreRequerido = 'Name is Required';
      this.correoRequerido = 'E-mail is Required';
      this.fechaRequerido = 'Date is required';
      this.telefonoRequerido = 'Telephone number is required';
      this.mensajeRequerido = 'Message is Required';
      this.placeholder = this.placeholder ? this.placeholder : 'I am interested in this property'
      this.nombrePlaceHolder = 'Full name'
      this.telefonoPlaceHolder = 'Telephone number';
      this.correoPlaceHolder = 'E-mail address';
      this.fechaPlaceHolder = 'Date'
      this.opcionesContacto = 'Contact options:';
      this.botonContacto = 'Contact';
      this.botonCita = 'Schedule an appointment';
    }else{
      this.placeholder = this.placeholder ? this.placeholder : 'Estoy interesad@ en esta propiedad'
      this.nombrePlaceHolder = 'Nombre completo'
      this.telefonoPlaceHolder = 'Número de telefono';
      this.correoPlaceHolder = 'Correo electrónico';
      this.fechaPlaceHolder = 'Fecha'
      this.nombreRequerido = 'El nombre es Requerido';
      this.correoRequerido = 'El correo es Requerido';
      this.fechaRequerido = 'El fecha es Requerida';
      this.telefonoRequerido = 'El Numero de telefono es Requerido';
      this.mensajeRequerido = 'El mensaje es Requerido';
      this.opcionesContacto = 'Opciones de contacto:';
      this.botonContacto = 'Contactar';
      this.botonCita = 'Agendar cita';
    }

    this.indexDB = window.indexedDB.open('navegador_control', 1);
    this.es = {
      firstDayOfWeek: 1,
      dayNames: ['domingo', 'lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'],
      dayNamesShort: ['dom', 'lun', 'mar', 'mié', 'jue', 'vie', 'sáb'],
      dayNamesMin: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
      monthNames: ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre',
        'octubre', 'noviembre', 'diciembre'],
      monthNamesShort: ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic'],
      today: 'Hoy',
      clear: 'Borrar',
    };

    const today = new Date();
    const month = today.getMonth();
    const year = today.getFullYear();
    const prevMonth = (month === 0) ? 11 : month - 1;
    const prevYear = (prevMonth === 11) ? year - 1 : year;
    const nextMonth = (month === 11) ? 0 : month + 1;
    const nextYear = (nextMonth === 0) ? year + 1 : year;
    this.minDate = new Date();
    this.minDate.setMonth(prevMonth);
    this.minDate.setFullYear(prevYear);
    this.maxDate = new Date();
    this.maxDate.setMonth(nextMonth);
    this.maxDate.setFullYear(nextYear);

    const invalidDate = new Date();
    invalidDate.setDate(today.getDate() - 1);
    this.invalidDates = [today, invalidDate];
  }

  modificarAccionIndexDB(): void {
    /*const data = db.transaction(['navegador_control'], 'readwrite');
    const object = data.objectStore('navegador_control');*/


    const request = this.indexDB.result.transaction(['navegador_control'], 'readwrite')
      .objectStore('navegador_control');
    const valor = this.cita ? 1 : 2;
    const get = request.get(valor);

    get.onsuccess = () => {
      const data = get.result;
      data.concedido = true;
      request.put(data);
    };
  }

  aceptar() {
    if (this.checked) {
      this.concedido = true;
    }
    this.displayModal = false;
  }

  submitForm(form: NgForm) {
    this.error = '';
    this.procesando = true;
    this.displayModal = true;
    this.contacto.url = this.router.url;
    this.contacto.tipo = this.tipo;
    this.contacto.inmueble = this.nombreInmueble;
    this.contactoService.post(this.contacto).toPromise()
      .then(() => {
        this.exito = true;
        this.confirmacion.emit(true);
        this.modificarAccionIndexDB();
        form.resetForm();
      })
      .catch(({error}) => {
        this.error = error.error || error;
      });
  }

  notifyMe() {
    // Comprobamos si el navegador soporta las notificaciones
    if (!('Notification' in window)) {
      alert('Este navegador no soporta las notificaciones del sistema');
    }

    // Comprobamos si ya nos habían dado permiso
    else if (Notification.permission === 'granted') {
      // Si esta correcto lanzamos la notificación
      this.notification = new Notification('ya está concedido');
    }

    // Si no, tendremos que pedir permiso al usuario
    else if (Notification.permission !== 'denied') {
      Notification.requestPermission(function (permission) {
        // Si el usuario acepta, lanzamos la notificación
        if (permission === 'granted') {
          this.notification = new Notification('Notificacion concedida');
        }
      });
    }

    // Finalmente, si el usuario te ha denegado el permiso y
    // quieres ser respetuoso no hay necesidad molestar más.
  }
}
