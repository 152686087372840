import { Component, Input, OnChanges } from '@angular/core';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'inmueble-whatsapp',
  templateUrl: './whatsapp.component.html',
  styleUrls: ['./whatsapp.component.scss'],
})
export class WhatsappComponent implements OnChanges {
  @Input() mensaje = 'Deseo información';
  @Input() whatsapp;
  ref = '';

  constructor() {
  }

  ngOnChanges() {
    this.ref = `https://api.whatsapp.com/send?phone=${this.whatsapp}&text=${this.mensaje}`
  }

}
