import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { INavegador } from '../../interface';

@Injectable({
  providedIn: 'root'
})
export class TokenService {

  constructor(@Inject(HttpClient) private http: HttpClient) { }

  post(): Observable<INavegador> {
    return this.http.post<any>('/api/navegador/token', {});
  }


  get(token: string): Observable<INavegador> {
    return this.http.get<any>(`/api/navegador/token/${token}`);
  }

  put(accionId: number, token: string): Observable<any> {
    return this.http.put<any>(`/api/navegador/token/${token}/accion/${accionId}`, {
      data: token
    });
  }
}
