import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IInmuebles } from '@inmueble/inmueble';

//TODO: Eliminar esta interfaz


@Component({
  selector: 'inmueble-inmuebles-resultados',
  templateUrl: './inmuebles-resultados.component.html',
  styleUrls: ['./inmuebles-resultados.component.scss']
})
export class InmueblesResultadosComponent {
  @Input() data: IInmuebles = [];
  @Output() OnChange = new EventEmitter<string>();
  @Input() dataOrden: { id: number, descripcion: string }[] = [];
  @Input() ordenId = '';
  @Input() busqueda = 'Casas en renta en mazatlán sinaloa';
  @Input() administrador;
  @Output() abrirFiltro = new EventEmitter<boolean>();
  horizontal = false;
  activo = false;

  regresarInfo(propiedad: string = '', valor: any = '') {
    switch (propiedad) {
      case 'precio-menor':
        this.dataOrden = valor;
        break;
      case 'precio-mayor':
        this.dataOrden = valor;
        break;
    }
    this.OnChange.emit(this.ordenId);
  }
  OnModal() {
    this.abrirFiltro.emit(true);
  }


  modal(estatus) {
    console.log('entro al otro componente', estatus);
    this.activo = estatus;
  }

}
