import { IsBoolean, IsNumber, IsString, ValidateNested } from 'class-validator'

export class CrmCuentaDto {
  id!: any
  plataformaId!: number
  descripcion!: string
  privada!: boolean
  apiKey!: string
  identificador!: string
  accessToken!: string
  agentes: any | { id: number }[]
  configuracion!: CrmCuentaConfiguracionDto
}

export class CrmCuentaConfiguracionDto {
  asignacion!: string
  expiracion!: number
  expiracionAsignacion!: number
  indexContinuidad!: number
  activo!: boolean
  senderId?: number
  proveedor?: string
}

export class SistemaMensajeria {
  tipoAplicacionId!: number
  nombre!: string
  identificador!: string
  dominio!: string
  accessToken!: string
}

export class CrmCuentaProspectoGet {
  cuenta!: any

  plataforma!: any

  prospecto!: any

  contenido!: CrmCuentaProspectoContenido

  contenidoId!: number
}

export class CrmCuentaProspectoDto {
  cuenta!: any

  plataforma!: any
}

class CRMProspectoDto {
  id?: number
  identificador?: string[]
  foto!: string | null
  nombre!: string
  @ValidateNested({ each: true })
  telefonos!: ProspectoTelefonoDto[]
  correos!: IProspectoCorreo[]
  etiquetas!: number[]
  busqueda?: string | null
  etiqueta?: ICatalogoEtiqueta[] | null
  nacimiento!: Date
}

export class CrmCuentaProspectoPost {
  cuenta!: CrmCuentaProspectoCuenta

  @IsNumber()
  plataformaId!: number

  @ValidateNested()
  prospecto!: CRMProspectoDto

  contenido!: CrmCuentaProspectoContenido
}

type ICatalogoEtiqueta = {
  id?: number
  etiqueta: string
  fecha?: number
}

type IProspectoCorreo = {
  id?: number
  prospectoId?: number
  correo: string
}

class ProspectoTelefonoDto {
  id?: number
  prospectoId?: number

  @IsString()
  telefono!: string

  @IsString()
  lada?: string
}

export class CrmCuentaProspectoCuenta {
  id!: number
  descripcion!: string
  alias?: string
}

export class CrmCuentaProspectoContenido {
  agenteId!: number

  mensaje!: any

  @IsNumber()
  fechaMensaje!: string

  @IsBoolean()
  estatus!: boolean

  masivo?: boolean
  subject?: string
}

export class CrmCuentaConfiguracion {
  @IsNumber()
  cuentaId!: number

  @IsString()
  identificador!: string

  configuracion!: any

  @IsString()
  accessToken!: string
}

export class CrmCuenta {
  @IsNumber()
  dominioId!: number

  @IsNumber()
  plataformaId!: number

  @IsString()
  descripcion!: string

  @IsBoolean()
  privada!: boolean

  @IsString()
  apiKey!: string

  @IsNumber()
  empresaId!: number

  alias!: string
}
