export class SendinBlueSmsCampaignPostDto {
  contenido!:string
  listaId!:number
  nombre!:string
  sender!:string
}
export class SendinblueSmsTransaccionalPostDto{
  sender!: string
  recipient!: string
  content!: string
}
