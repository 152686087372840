import { Component, Input, OnInit } from '@angular/core';
import { IInmueble } from '../../interface';


@Component({
  selector: 'inmueble-inmueble-similares',
  templateUrl: './inmueble-similares.component.html',
  styleUrls: ['./inmueble-similares.component.scss'],
})
export class InmuebleSimilaresComponent implements OnInit {
  @Input() info = true;
  @Input() caracteristicas = true;
  @Input() etiquetas = true;
  @Input() infoDentro = false;
  @Input() caracteristicasDentro = false;
  @Input() horizontal = false;
  @Input() horizontalSmall = false;
  @Input() index: number;
  @Input() tituloDentro = false;
  @Input() item: IInmueble | null = null;
  @Input() data: IInmueble[];
  @Input() idioma;
  resultados: IInmueble[];
verMas;
  constructor() {

  }

  ngOnInit(): void {
    this.verMas = this.idioma ==='en' ? 'Show more' : 'Mostrar más'
    this.resultados = this.data.slice(0, 6);
  }

  mostrarTodos(): void {
    this.resultados = this.data
  }
}
