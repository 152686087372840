import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AuthSesionJwtService {
  decode(jwt: string) {
    if (typeof jwt === 'string') {
      const parts = jwt.split('.');

      if (parts.length !== 3) {
        return null;
      }

      const decoded = this.urlBase64Decode(parts[1]);

      return decoded ? JSON.parse(decoded) : null;
    } else {
      return null;
    }
  }

  getExpirationDate(jwt: string): Date | unknown {
    const decoded = this.decode(jwt);

    if (decoded && decoded.exp) {
      return new Date(decoded.exp * 1000);
    } else {
      return null;
    }
  }

  isExpired(jwt: string): boolean {
    const expDate = this.getExpirationDate(jwt);

    if (!expDate) {
      return true;
    }

    return expDate < new Date();
  }

  urlBase64Decode(str): string {
    let output = str.replace('-', '+').replace('_', '/');

    switch (output.length % 4) {
      case 0:
        break;
      case 2:
        output += '==';
        break;
      case 3:
        output += '=';
        break;
      default:
        return null;
    }
    return window.atob(output); // polifyll https://github.com/davidchambers/Base64
  }
}
