export const meses = [
  'Enero',
  'Febrero',
  'Marzo',
  'Abril',
  'Mayo',
  'Junio',
  'Julio',
  'Agosto',
  'Septiembre',
  'Octubre',
  'Noviembre',
  'Diciembre',
]
export const meses3Dig = ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic']

export const formatoFecha = (fecha: number) => {
  const dia = new Date(+fecha)
  return `${dia.getDate()} de ${meses[dia.getMonth()]} del ${dia.getFullYear()}`
}
export const formatoFechaCorta = (fecha: number) => {
  const dia = new Date(+fecha)
  return `${dia.getDate().toString().padStart(2, '0')}-${meses3Dig[dia.getMonth()]}-${dia.getFullYear()}`
}
export const formatoHora = (fecha: number) => {
  const hora = new Date(+fecha)
  return `${hora.getHours().toString().padStart(2, '0')}:${hora.getMinutes().toString().padStart(2, '0')}`
}
export const formatoFechaNumero = (fecha: number) => {
  const dia = new Date(+fecha)
  return `${dia.getFullYear()}-${(dia.getMonth() + 1).toString().padStart(2, '0')}-${dia.getDate().toString().padStart(2, '0')}`
}
