import { Injectable } from '@angular/core'
import { Subject } from 'rxjs'
import { formatoFechaNumero } from '@decet/crm-libreria-shared'

@Injectable({
  providedIn: 'root',
})
export class ControlService {
  private indexDB: IDBOpenDBRequest
  private mostrarElementoSubject = new Subject<number | null>()
  mostrarElemento$ = this.mostrarElementoSubject.asObservable()

  mostrarComponente(accionId: number): void {
    this.indexDB = window.indexedDB.open('navegador_control', 1)
    this.indexDB.onsuccess = (event: any) => {
      const request = event.target.result
        .transaction(['navegador_control'], 'readwrite')
        .objectStore('navegador_control')
      const get = request.get(accionId)
      get.onsuccess = (event) => {
        const item = event.target.result
        console.log(event.target, 'ayufa2')
        console.log(item, 'ayuda')

        if (item) {
          item.count = item.count + 1
          const fechaExpiracion = formatoFechaNumero(new Date().getTime()) //formato dia
          if (formatoFechaNumero(item.fechaExpiracion.getTime()) !== fechaExpiracion) {
            item.rechazadoDiario = 0
          }
          request.put(item)

          let bloquearModal = item.rechazadoDiario >= item.catalogoAcciones.maximoDiario

          if (!bloquearModal) {
            bloquearModal =
              item.count %
                (item.concedido ? item.catalogoAcciones.cadaCuantoAceptacion : item.catalogoAcciones.cadaCuanto) !==
              0
          }

          this.mostrarElementoSubject.next(bloquearModal ? null : accionId)
        }
      }
    }
  }
}
