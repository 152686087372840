import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'inmueble-greetings',
  templateUrl: './greetings.component.html',
  styleUrls: ['./greetings.component.scss']
})

export class GreetingsComponent implements OnInit {

  constructor() {}

  ngOnInit(): void {}


}
