export class SendinBlueContacto{
  id!: number
  nombre!: string
  correoId!: number
  correo!: string
  contactoId?: number
  sendinblue?:any
}
export class SendinBlueContactoPost{
  nombre!:string
  correo!:string
}

export class SendinBlueEmailTransaccionalPostDto{
  sender!: number
  to!: SendinBlueEmailTransaccionalToDto
  htmlContent!: string
  textContent!: string
  subject!: string
}
export class SendinBlueEmailTransaccionalToDto{
  email!:string
  name!:string
}
