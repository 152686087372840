export class ProspectoActividadesDto {
  id!: number
  actividad!: ActividadDto
  prospectoId!: number
  fecha!: number
  detalle!: ActividadDetalleDto
}

export class ActividadDto {
  codigo!: string
  descripcion!: string
  id!: number
}
export class ActividadDetalleDto {
  inmueble!: string
  mensaje!: string
  tipo!: number
  url!: string
}
