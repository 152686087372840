export class VincularAgentePutDto {
  prospectoId!: number
  agenteIdNuevo!: number
  //TODO:INTERFAZ DE CUENTA
  cuentas!:any[]
}
export class VincularAgenteParamsDto{
  prospectoId!:number
}
export class DesVincularAgenteParamsDto{
  prospectoId!:number
  agenteId!:number
}
export class AgentesCuentasParamsDto{
  agenteId!:number
}
