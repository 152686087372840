import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { IInmuebles } from '../../interface';
import { TokenService } from '../apis/token.service';

@Component({
  selector: 'inmueble-inmubles',
  templateUrl: './inmubles.component.html',
  styleUrls: ['./inmubles.component.scss'],
})
export class InmublesComponent implements AfterViewInit, OnInit{
  @Input() data: IInmuebles = [];
  @Output() OnChange = new EventEmitter<string>();
  @Input() dataOrden: { id: number, descripcion: string }[] = [];
  @Input() ordenId = '';
  @Input() busqueda;
  @Input() administrador;
  @Input() etiqueta;
  @Output() abrirFiltro = new EventEmitter<boolean>();
  @Input() totalData;
  @Input() descripcion = false;
  @Input() resultados = true;
  @Input() limite;
  @Output() offset = new EventEmitter<number>();
  @Input() precioDentro = false;
  @Input() idioma;
  resultadoText ;
  dataShow: any = [];
  horizontal = false;
  activo = false;
  @Input() grid = 'tres';
  @ViewChild('div')
  div: ElementRef;
  noResultadoText;

  ngAfterViewInit() {
    this.div.nativeElement.classList.add(this.grid);
  }

  constructor() {

  }

  ngOnInit(): void {
    this.resultadoText = this.idioma === 'en' ? 'Result for ' : 'Resultado de ';
    this.noResultadoText = this.idioma === 'en' ? 'No results found' : 'No se encontraron resultados'
    }

  OnModal() {
    this.abrirFiltro.emit(true);
  }
  regresarInfo(propiedad: string = '', valor: any = '') {
    switch (propiedad) {
      case 'precio-menor':
        this.dataOrden = valor;
        break;
      case 'precio-mayor':
        this.dataOrden = valor;
        break
    }
    this.OnChange.emit(this.ordenId);
  }

  modal(estatus) {
    this.activo = estatus;
  }

  onPageChange(event) {
   this.offset.emit(event.first);
  }

  eliminarItem(id: number): void {
    console.log(id, 'ididid')
    this.data = this.data.filter((i) => i.id !== id)
  }
}



