import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { AuthSesionLoginService } from '../../../../auth/src/lib/sesion/apis/login.service';
import { ICredecial, ICredecialRespuesta, IUsuarioLogin } from '../../../../auth/types';
import { catchError, map } from 'rxjs/operators';
import { of } from 'rxjs';
import { Location } from '@angular/common';

@Component({
  selector: 'inmueble-inicio-sesion',
  templateUrl: './inicio-sesion.component.html',
  styleUrls: ['./inicio-sesion.component.scss'],
})
export class InicioSesionComponent {
  @Output() cierrate = new EventEmitter<void>();
  procesando: boolean;
  error = null;
  respuesta: any;
  credencial: ICredecial;
  user: IUsuarioLogin;
  form: unknown;

  constructor(
    private sesion: AuthSesionLoginService,
  private route: Router) {
    this.procesando = false;
    this.credencial = {username: '', password: ''};
  }

  aceptar(): void {
    if (this.procesando) {
      return;
    }

    this.error = null;
    this.procesando = true;

    this.sesion.auth(this.credencial)
      .pipe(
        map((response) => {
          this.respuesta = response;
          this.procesando = false;
          this.sesion.setSession(response)
          this.verificar();
          return response
        }),
        catchError(({ error }) => {
          this.procesando = false;
          this.error = error.error || error;
          return of(error)
        })
      )
      .subscribe()
  }

  verificar(): void {
    this.sesion.current().subscribe(data => {
      this.route.navigate(['/dashboard']);
      this.user = data;
      this.cierrate.emit();
    })
  }

  cerrar() {
    this.cierrate.emit();
  }
}
