<footer class="footer">
  <!-- start container -->
  <div class="container mx-auto pt-2 pb-4">
    <div class="flex flex-wrap overflow-hidden sm:-mx-1 md:-mx-px lg:-mx-2 xl:-mx-2">
      <div class="imagen-footer">
        <!-- Column 1 Content -->
        <img style="max-width: 34%;height:auto;" class="mx-auto" src="assets/img/logo_pacific_claro.png"
          alt="Logo Pacific and Caribbean">
      </div>
      <div
        class="w-full overflow-hidden text-align-center sm:my-1 sm:px-1 sm:w-1/2 md:my-px md:px-px md:w-1/2 lg:my-2 lg:px-2 lg:w-1/4 xl:my-2 xl:px-2 xl:w-1/4 pb-6">
        <!-- Column 2 Content -->
        <ul>
          <li class="leading-7 text-sm list-none">
            <a class="text-white text-bold" [routerLink]="['/',idioma, rutaLocal('nosotros')]">{{'footer.nosotros' | translate}}</a>
          </li>

          <li class="leading-7 text-sm list-none">
            <a class="text-white text-bold" [routerLink]="['/',idioma, rutaLocal('servicios')]">{{'footer.servicios' | translate}}</a>
          </li>

        </ul>
      </div>
      <div
        class="w-full overflow-hidden text-align-center sm:my-1 sm:px-1 sm:w-1/2 md:my-px md:px-px md:w-1/2 lg:my-2 lg:px-2 lg:w-1/4 xl:my-2 xl:px-2 xl:w-1/4 pb-6">
        <!-- Column 3 Content -->
        <ul class="">
          <li class="leading-7 list-none text-sm">
            <a class="text-white text-bold"  [routerLink]="['/',idioma, rutaLocal('conoce-nuestros-servicios')]">{{'footer.conoce.nuestros.servicios' | translate}}</a>
          </li>

          <li class="leading-7 list-none text-sm">
            <a class="text-white text-bold" [routerLink]="['/',idioma, rutaLocal('nosotros-vendemos-tu-casa')]">{{'footer.nosotros.vendemos.tu.casa' | translate}}</a>
          </li>
        </ul>
      </div>

      <div
        class="w-full overflow-hidden text-align-center sm:my-1 sm:px-1 sm:w-1/2 md:my-px md:px-px md:w-1/2 lg:my-2 lg:px-2 lg:w-1/4 xl:my-2 xl:px-2 xl:w-1/4 pb-6">
        <!-- Column 4 Content -->
        <ul class="">
          <li class="leading-7 list-none text-sm">
            <a class="text-white text-bold" [routerLink]="['/',idioma, rutaLocal('terminos')]" >{{'footer.terminos' | translate}}</a>
          </li>

          <li class="leading-7 list-none text-sm">
            <a class="text-white text-bold" [routerLink]="['/',idioma, rutaLocal('aviso-privacidad')]">{{'footer.aviso' | translate}}</a>
          </li>
        </ul>
      </div>
    </div>
    <!-- Start footer bottom -->
    <div class="pt-4 md:flex md:items-center md:justify-center " style="border-top:1px solid white">
      <ul class="text-align-center">
        <li class="md:mx-2 md:inline list-none leading-7 text-md"><span class="text-white text-bold">© 2021 Pacific & Caribbean</span>
        </li>
        <li class="md:mx-2 md:inline list-none leading-7 text-md"><span
          class="text-white text-bold">{{'footer.derechos' | translate}}</span></li>
        <li class="md:mx-2 md:inline list-none leading-7 text-md"><a class="text-white text-bold"
          href="https://decet.mx"><strong>Powered by Decet MX</strong></a></li>
      </ul>
    </div>
    <!-- end container -->
  </div>
</footer>
