import { faBlogger, faFacebookF, faInstagram, faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import { faComments, faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { FiltroPlataformasDto } from '../dtos/prospectos/filtros.dto'

export const mediosComunicacion = [
  { id: 1, descripcion: 'Facebook', icon: faFacebookF },
  { id: 2, descripcion: 'WhatsApp', icon: faWhatsapp },
  { id: 3, descripcion: 'Instagram', icon: faInstagram },
  { id: 4, descripcion: 'Página web', icon: faBlogger },
  { id: 5, descripcion: 'Email', icon: faEnvelope },
  { id: 6, descripcion: 'Sms', icon: faComments },
]
export const dataFiltroPlataformas: FiltroPlataformasDto[] = [
  {
    id: 1, descripcion: 'Facebook', prospectos: [], cuenta: {
      id: null,
      plataformaId: null,
      descripcion: '',

    },
  },
  {
    id: 2, descripcion: 'WhatsApp', prospectos: [], cuenta: {
      id: null,
      plataformaId: null,
      descripcion: '',
    },
  },
  {
    id: 3, descripcion: 'Instagram', prospectos: [], cuenta: {
      id: null,
      plataformaId: null,
      descripcion: '',
    },
  },
  {
    id: 4, descripcion: 'Página web', prospectos: [], cuenta: {
      id: null,
      plataformaId: null,
      descripcion: '',
    },
  },
  {
    id: 5, descripcion: 'Email', prospectos: [], cuenta: {
      id: null,
      plataformaId: null,
      descripcion: '',
    },
  },
  {
    id: 6, descripcion: 'Sms', prospectos: [], cuenta: {
      id: null,
      plataformaId: null,
      descripcion: '',
    },
  },
]
