<div class="modal" tabindex="-1">
  <div class="modal-sesion">
    <div class="modal-content">
      <div class="modal-header">
        <button class="close"
          type="button"
          (click)="cerrar()">
          <i class="pi pi-times"></i>
        </button>
      </div>
      <div class="modal-body">
        <div class="subtitulo">
          <h2 class="modal-title">
            Inicia sesión</h2>
          <span class="barra">&nbsp;
        </span>
        </div>
        <form
          [name]="form"
          (ngSubmit)="aceptar()">

          <input type="text"
            class="form-control form-sesion"
            [ngClass]="{'form-invalid':error}"
            id="exampleInputEmail1"
            aria-describedby="emailHelp"
            placeholder="Correo / Usuario"
            name="username"
            [(ngModel)]="credencial.username">
          <input type="password"
            class="form-control form-sesion"
            id="exampleInputPassword1"
            placeholder="Contraseña"
            name="password"
            [(ngModel)]="credencial.password">
          <span *ngIf="error" class="text-danger block">{{error}}</span>
          <a class="texto-pequeno" >Olvide mi contraseña</a>
          <input class="btn-iniciar"
           type="submit" value="Iniciar sesión">
          <!--          <button class="btn-facebook"><span> <i class="fab fa-facebook-f"></i></span>Conectar-->
          <!--            con Facebook-->
          <!--          </button>-->
        </form>
      </div>
    </div>
  </div>
</div>
