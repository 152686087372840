import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { catchError, finalize, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AutorizacionService implements HttpInterceptor {

  constructor(private router: Router) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let request = null;

    const ngToken = localStorage.getItem('ng-token') || '';
    const ngCuenta = localStorage.getItem('ng-cuenta') || '';
    const headers = new HttpHeaders({
      Authorization: `Barrer ${ngToken}`,
      Account: ngCuenta,
      //TODO: obtener de algún servicio
      Company: '2'
    });

    request = req.clone({
      headers,
    });


    return next.handle(request).pipe(
      map((response: HttpResponse<any>) => {
        if (response.status === 201) {
          console.log('aqui lo puedo tronar');
        }

        return response;
      }),
      catchError((error: HttpErrorResponse) => {
        if (error.status === 403) {
          this.router.navigateByUrl('/authorization')
        }

        if (error.status === 401) {
          this.router.navigateByUrl('/session/login')
        }

        return throwError(error);
      }),
      finalize(() => {
        // al finalizar de armar la petición HTTP
      }));
  }
}

export const InterceptorProvider = [{
  provide: HTTP_INTERCEPTORS,
  useClass: AutorizacionService,
  multi: true,
}];

