import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IContacto } from '../../interface';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ContactoService {

  constructor(@Inject(HttpClient) private http: HttpClient) {
  }

  post(data: IContacto): Observable<IContacto> {
    return this.http.post<IContacto>(`/api/contacto`, data);
  }
}
