import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'inmueble-aviso-privacidad',
  templateUrl: './aviso-privacidad.component.html',
  styleUrls: ['./aviso-privacidad.component.scss'],
})
export class AvisoPrivacidadComponent implements OnInit {
  idioma = localStorage.getItem('lang')

  constructor() {}

  ngOnInit(): void {}
}
