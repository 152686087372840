export class listaChatDto {
  id?: number
  activo?: number
  capturaFecha?: Date
  prospecto?: prospectoChatDto
  ultimoMensaje?: any
  busqueda?: string
  tipoData?: number

}

export class prospectoChatDto {
  id?: number
  foto?: string
  nombre?: string
  telefonos?: ProspectoTelefonoDto[]
  correos?: ProspectoChatCorreoDto[]
}

export class ProspectoChatCorreoDto {
  correo?: string
}
export class ProspectoTelefonoDto {
  telefono?: string
}
