import { IsNumber, IsNumberString } from 'class-validator';

export class ProspectoClasificacionDto{
  id!: number
  activo!: boolean
  captura_fecha!: number
  clasificacion!: number
  nota!: string
}
export class ProspectoClasificacionParamsDto{
  @IsNumberString()
  prospectoId!:number
}
export class ProspectoClasificacionPostDto{
  @IsNumber()
  clasificacion!:number
  nota!:string
}
