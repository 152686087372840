export class TareasPendientesDto {
  id!: number
  tarea!: TareaPendienteTareaDto
  prospecto!: TareaPendienteProspectoDto
}
export class TareaPendienteTareaDto {
  id!: number
  descripcion!: string
  capturaFecha!: number
  capturaUsuarioId!: number
  fecha!: number
  fecha_recordatorio!: number
}
export class TareaPendienteProspectoDto {
  id!: number
  nombre!: string
  foto!: string
}
