<main style="width: 80%; max-width: 900px;margin: 0 auto">
  <p><strong>{{'aviso.seccion1.titulo' | translate}}</strong></p>
  <br>
  <p><strong>{{'aviso.seccion1.p&c' | translate}}</strong> {{'aviso.seccion1.uno' | translate}} <strong>
    {{'aviso.seccion1.dos' | translate}}</strong> {{'aviso.seccion1.tres' | translate}}<strong>
    {{'aviso.seccion1.cuatro' | translate}}</strong>{{'aviso.seccion1.cinco' | translate}}</p>
  <br>
  <p><strong> {{'aviso.seccion2.titulo' | translate}}</strong></p>
  <br>
  <p>{{'aviso.seccion2.uno' | translate}}<strong>{{'aviso.seccion2.dos' | translate}}</strong>
    {{'aviso.seccion2.tres' | translate}}<strong> {{'aviso.seccion2.cuatro' | translate}}</strong> {{'aviso.seccion2.cinco' | translate}}
    <strong> {{'aviso.seccion2.seis' | translate}}</strong>{{'aviso.seccion2.siete' | translate}}</p>
  <br>
  <ol start="1">
    <li><strong> {{'aviso.seccion2.subtitulo.uno' | translate}}</strong></li>
  </ol>
  <p> {{'aviso.seccion2.subtitulo.descripcion' | translate}}</p>
  <p>{{'aviso.seccion2.subtitulo.punto.uno' | translate}}</p>
  <p>{{'aviso.seccion2.subtitulo.punto.dos' | translate}}</p>
  <p>{{'aviso.seccion2.subtitulo.punto.tres' | translate}}</p>
  <p>{{'aviso.seccion2.subtitulo.punto.cuatro' | translate}}</p>
  <p>{{'aviso.seccion2.subtitulo.punto.cinco' | translate}}</p>
  <br>
  <p>{{'aviso.seccion2.informacion.recabada' | translate}}</p>
  <ul>
    <li>{{'aviso.seccion2.informacion.recabada.personalmente' | translate}}</li>
    <li>{{'aviso.seccion2.informacion.recabada.directamente' | translate}}</li>
    <li>{{'aviso.seccion2.informacion.recabada.indirectamente' | translate}}</li>
  </ul>
  <br>
  <p>{{'aviso.seccion2.informacion.recabada.descripcion' | translate}}</p>
  <br>
  <ul>
    <li>{{'aviso.seccion2.informacion.recabada.punto.uno' | translate}}</li>
    <li>{{'aviso.seccion2.informacion.recabada.punto.dos' | translate}}</li>
    <li>{{'aviso.seccion2.informacion.recabada.punto.tres' | translate}}</li>
    <li>{{'aviso.seccion2.informacion.recabada.punto.cuatro' | translate}}</li>
  </ul>
  <br>
  <ol start="2">
    <li><strong> {{'aviso.seccion2.subtitulo.dos' | translate}}</strong></li>
  </ol>
  <p> {{'aviso.seccion2.subtitulo.dos.descripcion' | translate}}</p>
  <br>
  <p> {{'aviso.seccion2.subtitulo.dos.descripcion.dos' | translate}}</p>
  <ul>
    <li>{{'aviso.seccion2.subtitulo.dos.punto.uno' | translate}}</li>
    <li>{{'aviso.seccion2.subtitulo.dos.punto.dos' | translate}}</li>
    <li>{{'aviso.seccion2.subtitulo.dos.punto.tres' | translate}}</li>
    <li>{{'aviso.seccion2.subtitulo.dos.punto.cuatro' | translate}}</li>
    <li>{{'aviso.seccion2.subtitulo.dos.punto.cinco' | translate}}</li>
    <li>{{'aviso.seccion2.subtitulo.dos.punto.seis' | translate}}</li>
    <li>{{'aviso.seccion2.subtitulo.dos.punto.siete' | translate}}</li>
    <li>{{'aviso.seccion2.subtitulo.dos.punto.ocho' | translate}}</li>
    <li>{{'aviso.seccion2.subtitulo.dos.punto.nueve' | translate}}</li>
  </ul>

  <ol start="3">
    <li><strong> {{'aviso.seccion2.subtitulo.tres' | translate}}</strong></li>
  </ol>
  <p> {{'aviso.seccion2.subtitulo.tres.descripcion' | translate}}</p>
  <ul>
    <li> {{'aviso.seccion2.subtitulo.tres.punto.uno' | translate}}</li>
    <li> {{'aviso.seccion2.subtitulo.tres.punto.dos' | translate}}</li>
    <li> {{'aviso.seccion2.subtitulo.tres.punto.tres' | translate}}</li>
  </ul>
  <ol start="4">
    <li><strong>{{'aviso.seccion2.subtitulo.cuatro' | translate }}</strong></li>
  </ol>
  <p>{{'aviso.seccion2.subtitulo.cuatro.descripcion' | translate}}</p>
  <ol start="5">
    <li><strong>{{'aviso.seccion2.subtitulo5' | translate}}</strong></li>
  </ol>
  <p>{{'aviso.seccion2.subtitulo5.descripcion' | translate}}</p>
  <ol start="6" >
    <li><strong>{{'aviso.seccion2.subtitulo6' | translate}}</strong></li>
  </ol>
  <p>{{'aviso.seccion2.subtitulo6.descripcion' | translate}}</p>
  <ol start="7">
    <li><strong>{{'aviso.seccion2.subtitulo7' | translate}}</strong></li>
  </ol>
  <p>{{'aviso.seccion2.subtitulo7.descripcion' | translate}}</p>
  <ul>
    <li>{{'aviso.seccion2.subtitulo7.li1' | translate}}</li>
    <li>{{'aviso.seccion2.subtitulo7.li2' | translate}}</li>
    <li>{{'aviso.seccion2.subtitulo7.li3' | translate}}</li>
  </ul>
  <p>{{'aviso.seccion2.subtitulo7.descripcion2' | translate}}</p>
  <p>{{'aviso.seccion2.subtitulo7.descripcion3' | translate}}</p>
</main>
<inmueble-whatsapp></inmueble-whatsapp>
