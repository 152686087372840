export class VincularProspectoDataDto {
  id!: number
  foto!: string
  agenteId!: number
  nombre!: string
  busqueda!: string
}
export class VincularProspectoPostDto {
prospectoId!:number
prospectoIdNuevo!:number
}
export class VincularProspectoParamsDto {
  prospectoId!:number
}
