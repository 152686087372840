import { Component, OnInit } from '@angular/core'

const traducciones = [
  { es: '', en: '' },
  { es: 'nosotros', en: 'about' },
  { es: 'servicios', en: 'services' },
  { es: 'conoce-nuestros-servicios', en: 'learn-about-our-services' },
  { es: 'nosotros-vendemos-tu-casa', en: 'We-sell-your-house' },
  { es: 'terminos', en: 'Terms-and-Conditions' },
  { es: 'aviso-privacidad', en: 'privacy-notice' },
]

@Component({
  selector: 'inmobiliaria-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  idioma = localStorage.getItem('lang')

  constructor() {}

  ngOnInit(): void {}
  rutaLocal(seccion: string): string {
    const traduccion = traducciones.find((item) => item.es === seccion || item.en === seccion)
    return traduccion[this.idioma]
  }
}
