<form
  class="contactanos"
  name="form"
  #form="ngForm"
  autocomplete="off"
  (ngSubmit)="submitForm(form)">
  <div class="form-control">
    <input
      #nombre="ngModel"
      name="nombre"
      [(ngModel)]="contacto.nombre"
      class="input-item"
      type="text"
      required
      [placeholder]="nombrePlaceHolder">
    <div *ngIf="nombre.touched && nombre.errors">
      <span class="text-danger" *ngIf="nombre.errors.required">{{nombreRequerido}}</span>
    </div>
  </div>
  <div class="form-control">
    <input
      class="input-item"
      type="email"
      required
      name="correo"
      #correo="ngModel"
      [(ngModel)]="contacto.correo"
      [pattern]="patron"
      [minLength]="5"
      [maxLength]="30"
      [placeholder]="correoPlaceHolder">
    <div *ngIf="correo.touched && correo.errors">
      <span class="text-danger" *ngIf="correo.errors.required">{{correoRequerido}}</span>
    </div>
    <div *ngIf="!correo.touched && correo.errors">
      <span class="text-danger" *ngIf="!correo.errors.required">El correo no tiene formato correcto</span>
    </div>
  </div>
  <div class="form-control">
    <input
      required
      name="telefono"
      [(ngModel)]="contacto.telefono"
      #telefono="ngModel"
      [minLength]="10"
      [maxLength]="10"
      class="input-item"
      type="number"
      [placeholder]="telefonoPlaceHolder">
    <div *ngIf="telefono.touched && telefono.errors">
      <span class="text-danger" *ngIf="telefono.errors.required">{{telefonoRequerido}}</span>
    </div>
  </div>
  <div *ngIf="!cita" class="form-control">
    <textarea
      [(ngModel)]="contacto.mensaje"
      name="mensajee"
      class="input-item area"
      #mensajee='ngModel'
      [placeholder]="placeholder"
      rows="6"
      required>  </textarea>
    <div *ngIf="mensajee.touched && mensajee.errors">
      <span class="text-danger" *ngIf="mensajee.errors.required">{{mensajeRequerido}}</span>
    </div>
  </div>
  <div *ngIf="cita" class="form-control">
    <p-calendar [(ngModel)]="contacto.mensaje"
      [ngClass]="{'ng-touched ng-invalid':fecha.touched && fecha.errors}"
      required
      class="fecha"
      name="fecha"
      [showTime]="true"
      #fecha="ngModel"
      [disabledDates]="invalidDates"
      [placeholder]="fechaPlaceHolder"></p-calendar>
    <div *ngIf="fecha.touched && fecha.errors">
      <span class="text-danger" *ngIf="fecha.errors.required">{{fechaRequerido}}</span>
    </div>


  </div>
  <input *ngIf="!cita"
    class="btn btn-primary"
    type="submit"
    [disabled]="form.invalid"
    (click)="notifyMe()"
    [value]="botonContacto">
  <input *ngIf="cita"
    class="btn btn-primary"
    type="submit"
    [disabled]="form.invalid"
    [value]="botonCita">
</form>
<div class="otro-contacto">
  <p>{{opcionesContacto}}
  </p>
  <div>
    <a target="_blank" href='https://api.whatsapp.com/send?phone={{whatsapp}}&text={{mensaje}}'><span><i
      class="fab fa-whatsapp"></i></span></a>
    <a href="tel:+{{telefonoI}}"><span><i class="fas fa-phone-alt"></i></span></a>
    <a href="mailto:{{correoI}}"><span><i class="fas fa-envelope"></i></span></a>

  </div>
</div>

<p-dialog header="Contacto"
  [closable]="false"
  *ngIf="displayModal && exito && modal"
  [(visible)]="displayModal && exito && modal"
  [modal]="true" [style]="{width: '50vw'}"
  [baseZIndex]="10000"
  [draggable]="false"
  [resizable]="false">
  <div *ngIf="error">
    <p class="p-m-0 --text-error">
      {{error}}
    </p>
  </div>
  <p><i class="pi pi-check-circle text-green-600" *ngIf="cita"
  ></i>Se envio la información correctamente.</p>
  <p>En brevedad uno de nuestros agentes se pondra en contacto.</p>

  <div class="flex gap-4" *ngIf="!concedido">
    <img src="assets/img/notificaciones/notificacion.svg" alt="icon-notificacion" class="w-1/12">
    <div>
      <strong>Acepta las notificaciones y entérate de todo nuestros avisos</strong>
    </div>
  </div>

  <ng-template pTemplate="footer">
    <p-button (click)="aceptar()"
      label="Aceptar"
      styleClass="btn btn-primary">
    </p-button>
  </ng-template>
</p-dialog>
